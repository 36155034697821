html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.react-datepicker {
  color: #555555 !important;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: 0 !important;
}
.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__day-name {
  font-weight: bold;
  text-decoration: underline;
}

.react-datepicker__day--selected {
  background-color: #2ea59a !important;
  border: 2px solid #2ea59a;
  border-radius: 4px;
}

.progress-bar {
  background: #2462bc;
}

/* revisit: next iteration - move this as a styled component */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 14px;

  margin-top: 4px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -2px;
  bottom: -3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  border-radius: 50%;

  box-shadow: 1px 1px 5px #7b7b7b;
}

/* input:checked + .slider:before {
  background-color: #0b60f0;
} */

input:checked + .slider {
  background-color: #9fbff6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  bottom: 28px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.highlight {
  background-color: #fff2b7;
  color: #586272;

  padding: 0.2rem 0;
}

/* 
  Override the max-width of react boostrap popover(tooltip) 
  https://github.com/facultyai/dash-bootstrap-components/issues/12
*/
.popover {
  max-width: 333px;
}
