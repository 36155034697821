@font-face {
  font-family: Graphik;
  font-display: swap;
  src: url('https://www.upgrade.com/assets/fonts/Graphik-Black-Web.eot');
  src: url('https://www.upgrade.com/assets/fonts/Graphik-Black-Web.eot?#iefix') format('embedded-opentype'),
    url('https://www.upgrade.com/assets/fonts/Graphik-Black-Web.woff2') format('woff2'),
    url('https://www.upgrade.com/assets/fonts/Graphik-Black-Web.woff') format('woff');

  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik;
  font-display: swap;
  src: url('https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.eot');
  src: url('https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
    url('https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.woff') format('woff');

  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik;
  font-display: fallback;
  src: url('https://www.upgrade.com/assets/fonts/Graphik-Regular-Web.eot');
  src: url('https://www.upgrade.com/assets/fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
    url('https://www.upgrade.com/assets/fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('https://www.upgrade.com/assets/fonts/Graphik-Regular-Web.woff') format('woff');

  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik;
  font-display: swap;
  src: url('https://www.upgrade.com/assets/fonts/Graphik-Light-Web.eot');
  src: url('https://www.upgrade.com/assets/fonts/Graphik-Light-Web.eot?#iefix') format('embedded-opentype'),
    url('https://www.upgrade.com/assets/fonts/Graphik-Light-Web.woff2') format('woff2'),
    url('https://www.upgrade.com/assets/fonts/Graphik-Light-Web.woff') format('woff');

  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  src: url('https://www.upgrade.com/assets/fonts/Roboto-300.woff') format('woff');

  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  src: url('https://www.upgrade.com/assets/fonts/Roboto-bold.woff') format('woff');

  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
