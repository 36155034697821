.today {
  border: 2px solid #0a8771;
  border-radius: 4px;
}

.monthlyDueDay {
  border: 2px solid #fca82a;
  border-radius: 4px;
}

.loanMaturityDate {
  border: 2px solid #dff3f1;
  border-radius: 4px;
}
